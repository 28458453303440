var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"receivable-list"},[_c('section',{staticClass:"content"},[_c('section',{staticStyle:{"margin-bottom":"1%"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.createHandle}},[_c('i',{staticClass:"icon iconfont icon-new",staticStyle:{"margin-right":"5px"}}),_vm._v(" "+_vm._s(_vm.$t('新增应收单'))+" ")])],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":6}},[_c('a-input',{attrs:{"allow-clear":"","placeholder":_vm.$t('请输入应收单号')},model:{value:(_vm.formData.orderSn),callback:function ($$v) {_vm.$set(_vm.formData, "orderSn", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.orderSn"}})],1),_c('a-col',{attrs:{"span":6}},[_c('date-range-picker',{attrs:{"needhms":false,"placeholder":_vm.$t(`记账日期`),"start-time":_vm.formData.accountingDateStart,"end-time":_vm.formData.accountingDateEnd},on:{"get_date":_vm.chooseAccountingDate}})],1),_c('a-col',{attrs:{"span":6}},[_c('CommonSelect',{attrs:{"isBu":true,"list":_vm.$store.state.settlementBuList,"code":_vm.formData.businessUnitCode,"name":_vm.formData.businessUnitName,"placeholder":_vm.$t(`收款组织`)},on:{"update:code":function($event){return _vm.$set(_vm.formData, "businessUnitCode", $event)},"update:name":function($event){return _vm.$set(_vm.formData, "businessUnitName", $event)}}})],1),_c('a-col',{attrs:{"span":6}},[_c('CommonQuerySelect',{attrs:{"api":"getCommonList","placeholder":_vm.$t('客户'),"code":_vm.formData.customerCode,"params":{
            businessUnitCode: _vm.formData.businessUnitCode,
            tableName: 'customer',
          }},on:{"update:code":function($event){return _vm.$set(_vm.formData, "customerCode", $event)}}})],1)],1),_c('a-row',{staticStyle:{"margin-top":"15px"},attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":6}},[_c('CommonSelect',{attrs:{"list":_vm.receivable_order_type,"code":_vm.formData.orderType,"placeholder":_vm.$t('单据类型')},on:{"update:code":function($event){return _vm.$set(_vm.formData, "orderType", $event)}}})],1),_c('a-col',{attrs:{"span":6}},[_c('a-button',{staticClass:"btnBgc",on:{"click":function($event){return _vm.searchHandle(1)}}},[_c('i',{staticClass:"iconfont",staticStyle:{"margin-right":"5px"}},[_vm._v("")]),_vm._v(" "+_vm._s(_vm.$t(`查询`))+" ")]),_c('a-button',{on:{"click":_vm.reset}},[_c('a-icon',{attrs:{"type":"redo"}}),_vm._v(" "+_vm._s(_vm.$t(`重置`))+" ")],1)],1)],1),_c('CommonTable',{ref:"CommonTableRef",attrs:{"statusList":_vm.receivable_order_status,"selectedRowKeys":_vm.selectedRowKeys,"selectedRows":_vm.selectedRows,"showSelect":true,"size":"small","scroll":{ x: 1500, y: _vm.scrollHeight },"columns":_vm.tableColumns,"data-source":_vm.tableData,"rowKey":"id","total":_vm.total,"loading":_vm.tableLoading},on:{"update:selectedRowKeys":function($event){_vm.selectedRowKeys=$event},"update:selected-row-keys":function($event){_vm.selectedRowKeys=$event},"update:selectedRows":function($event){_vm.selectedRows=$event},"update:selected-rows":function($event){_vm.selectedRows=$event},"update:columns":function($event){_vm.tableColumns=$event},"select":_vm.onSelectChange,"search":_vm.queryOrderList},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex-ct"},[_c('OperateBtn',{attrs:{"api":"operateReceivableOrder","text":_vm.$t('审核'),"params":{
              action: 'check',
              idList: _vm.selectedRowKeys,
            },"disabled":_vm.selectedRowKeys.length === 0},on:{"success":_vm.searchHandle}}),_c('PushBtn',{staticClass:"ml10",attrs:{"pageType":"list","docType":_vm.docType,"selectedRows":_vm.selectedRows,"disabled":_vm.selectedRows.length === 0}}),_c('VoucherBtn',{staticClass:"ml10",attrs:{"docType":_vm.docType,"selectedRows":_vm.selectedRows,"disabled":_vm.selectedRows.length === 0},on:{"close":_vm.searchHandle}})],1)]},proxy:true},{key:"orderSn",fn:function({ record }){return [_c('a',{on:{"click":function($event){return _vm.viewDetail(record)}}},[_vm._v(_vm._s(record.orderSn))])]}},{key:"amountWithoutTax",fn:function({ record }){return [_c('PriceInput',{attrs:{"showTxt":true,"currency":record.functionalCurrency,"value":record.amountWithoutTax}})]}},{key:"taxAmount",fn:function({ record }){return [_c('PriceInput',{attrs:{"showTxt":true,"currency":record.functionalCurrency,"value":record.taxAmount}})]}},{key:"receivableAmount",fn:function({ record }){return [_c('PriceInput',{attrs:{"showTxt":true,"currency":record.functionalCurrency,"value":record.receivableAmount}})]}},{key:"invoicedAmount",fn:function({ record }){return [_c('PriceInput',{attrs:{"showTxt":true,"currency":record.functionalCurrency,"value":record.invoicedAmount}})]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }